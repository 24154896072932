angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.notifications', {
            url: '/calendar',
            views: {
                "main": {
                    controller: 'NotificationsCtrl',
                    templateUrl: 'web/main/notifications/notifications.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Notifications'
            }
        });
    })


    .controller('NotificationsCtrl', function($scope, session, notificacionService, uiCalendarConfig, $state, $translate) {
        var that = this;
        that.notificacionesDates = [];
        that.dateSelected = new Date();



        var d = moment();
        that.yearSelected = d.format('YYYY');
        that.monthSelected = d.format('M');
        that.daySelected = d.format('D');

        notificacionService.getNotificaciones().then(function (response) {
            for (var i in response.data) {
                var date = moment(response.data[i].date);

                if (!that.notificacionesDates[date.format('YYYY')]) {
                    that.notificacionesDates[date.format('YYYY')] = [];
                }
                if (!that.notificacionesDates[date.format('YYYY')][date.format('M')]) {
                    that.notificacionesDates[date.format('YYYY')][date.format('M')] = [];
                }
                if (!that.notificacionesDates[date.format('YYYY')][date.format('M')][date.format('D')]) {
                    that.notificacionesDates[date.format('YYYY')][date.format('M')][date.format('D')] = {
                        notificacions: [],
                        tipos: []
                    };
                }

                that.notificacionesDates[date.format('YYYY')][date.format('M')][date.format('D')].notificacions.push(response.data[i]);

                if (!that.notificacionesDates[date.format('YYYY')][date.format('M')][date.format('D')].tipos[response.data[i].type]) {
                    that.notificacionesDates[date.format('YYYY')][date.format('M')][date.format('D')].tipos[response.data[i].type] = 1;
                } else {
                    that.notificacionesDates[date.format('YYYY')][date.format('M')][date.format('D')].tipos[response.data[i].type] =
                        that.notificacionesDates[date.format('YYYY')][date.format('M')][date.format('D')].tipos[response.data[i].type] + 1;
                }
            }

            that.loaded = true;
        });

        $scope.dayClick = function (date) {
            var d = moment(date);
            that.yearSelected = d.format('YYYY');
            that.monthSelected = d.format('M');
            that.daySelected = d.format('D');
        };


        $scope.setDayContent = function (dateDay) {
            var date = moment(dateDay);
            var y = date.format('YYYY');
            var m = date.format('M');
            var d = date.format('D');

            if (that.notificacionesDates[y] && that.notificacionesDates[y][m] && that.notificacionesDates[y][m][d]) {
                var n = (that.notificacionesDates[y][m][d]);
                
                var tal = '<div>';

                for (var i = 0; i < that.types.length; i++) {

                    if (n.tipos[that.types[i]]) {
                        tal += '<div><div class="btn circle-notifications '+that.types[i]+' m-r-10"> </div><span class="d-sm-down">'+ that.msg[that.types[i]] +' </span></div>';
                    }
                }
                /* for (var i = 0; i < n.length; i++) {
                     tal += '<span><i class="' + that.icons[n[i].type] + '"/></span>';
                 }
                 */
                tal += '</div>';
                return tal;
            }
        };



        that.go = function (n) {
            if (n.type == "NEW_USER") {
                $state.go('main.team');
            } else {
                $state.go('main.document', {
                    id: n.ref
                });
            }
        };



        that.msg = {
            DOCUMENT_SIGNED: $translate.instant('Firmado'),
            CREATED_DOCUMENT: $translate.instant('Envío'),
            DOCUMENT_COMPLETED: $translate.instant('Autofirma'),
            NEW_USER: $translate.instant('Usuario'),
            DOCUMENT_REFUSED: $translate.instant('Rechazado')

        };
        that.icons = {
            DOCUMENT_SIGNED: 'fa fa-check',
            CREATED_DOCUMENT: 'fa fa-pencil',
            DOCUMENT_COMPLETED: 'fa fa-university',
            NEW_USER: 'fa fa-close',
            DOCUMENT_REFUSED: 'fa fa-cogs'
        };
        that.types = [
            'DOCUMENT_SIGNED',
            'CREATED_DOCUMENT',
            'DOCUMENT_COMPLETED',
            'NEW_USER',
            'DOCUMENT_REFUSED'
        ];

    });
